import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOthers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/other/others', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOther(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/other/others/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteOther(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/other/others/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addOther(ctx, otherData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/other/others', { other: otherData })
          .then(response => resolve(response))
          // eslint-disable-next-line
          .catch(error => {
            // console.log(error)
            // console.log(error.response)
            return reject(error.response)
          })
      })
    },
    fetchCompanies() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/company/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchObjects() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/object/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMachines() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/machine/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
